import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import {
  About,
  Apartments,
  ContactAgent,
  ContactUs,
  Home,
  TenAnts,
  ViewApartments
} from "./pages";
import Layout from "./components/layout/Layout";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/10ants" element={<TenAnts />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/contact-agent" element={<ContactAgent />} />
        <Route path="/apartments" element={<Apartments />} />
        <Route path="/apartments/:id" element={<ViewApartments />} />
      </Routes>
    </Layout>
  );
}

export default App;
