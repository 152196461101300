export const listingsData = [
    {
      id: '1',
      name:'Sandton',
      location:'South Africa',
      price:'$398',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893862/10Ants/zllfdz2s5y2slcxnv7kf.png',
      bedrooms:'1',
      bathrooms:'2',
      country: 'South Africa',
      description: "One bedroom modern apartment is located in a 5 star hotel (Capital on the Park) on Katherine street in Sandton. Daily cleaning service, fitness center, and a shared lounge. Room service, a classy restaurant, swimming pool, spa, and sauna, along with other amenities you would expect in a 5-star hotel. Unlimited Wi-Fi is available in every part of the building. Smart TVs are provided in both the living room and bedroom, with access to DSTV, Netflix, YouTube, Apple TV+, and more. No load-shedding, as the building has permanent silent backup generators! The apartment is located on the 5th floor with a beautiful view of Sandton. We provide guests with air-conditioned rooms, each equipped with a desk, kettle, safety deposit box, oven, and microwave. There is an on-site snack bar, and guests can also make use of the business area. Sandton City Mall is 1.8 km from the accommodation, while Parkview Golf Club is 10 km away. The nearest airport is O.R. Tambo International, located 28 km from The Capital on the Park. You have full access to all the hotel amenities. Daily cleaning service, 24-hour reception, 24-hour in room dining (room service), Complimentary uncapped high-speed Wi-Fi.",
      images: ['https://res.cloudinary.com/welkom-u/image/upload/v1735388768/10Ants/k5xyc2axbxekedhvst4r.jpg','https://res.cloudinary.com/welkom-u/image/upload/v1735388847/10Ants/u65oecshxtlpdkkf25me.jpg','https://res.cloudinary.com/welkom-u/image/upload/v1735388892/10Ants/essobzposlhisg13iq6q.jpg','https://res.cloudinary.com/welkom-u/image/upload/v1735388926/10Ants/dzhxmamasz914kpzxexr.jpg','https://res.cloudinary.com/welkom-u/image/upload/v1735388959/10Ants/q2rosbgagmthw46jji2g.jpg','https://res.cloudinary.com/welkom-u/image/upload/v1731893862/10Ants/zllfdz2s5y2slcxnv7kf.png'],
      utilities: ['security','gym','elevator']
    },
    {
      id: '2',
      name:'Blue Water',
      location:'Lagos',
      price:'$175',
      image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731893199/10Ants/xishomf0stkhz5hkhagb.jpg',
      bedrooms:'3',
      bathrooms:'3',
      country: 'Lagos',
      description: 'This secure Apartment in the heart of Lekki/Victoria Island  boasts  gorgeous views of the Atlantic Ocean. With its modern amenities, beautiful swimming pools, the apartment has everything you need for a relaxing holiday. Close to restaurants, beach, Cinemas.',
      images: [],
      utilities: []
    },
    {
      id: '3',
      name:'Metropolis',
      location:'Lagos',
      price:'$160',
      image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731894054/10Ants/xsw065paxsu2apf0rgnm.jpg',
      bedrooms:'2',
      bathrooms:'2',
      country: 'Lagos',
      description: '',
      images: [],
      utilities: []
    },
    {
      id: '4',
      name:'Periwinkle Residences',
      location:'Lekki Lagos',
      price:'$130',
      image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731894149/10Ants/ktl6ojrz9jfagbeqendy.jpg',
      bedrooms:'2',
      bathrooms:'3',
      country: 'Lagos',
      description: "Welcome to this cozy 2-bedroom home nestled in the heart of Lekki Phase 1. Situated in the quiet and secure Periwinkle estate, this charming residence offers comfort and convenience. With its central location, you'll have easy access to nearby amenities, making it an ideal choice for those seeking a tranquil yet well-connected living space ",
      images: [],
      utilities: []
    },
    // {
    //   id: '5',
    //   name:'HOK Apartments',
    //   location:'Lagos',
    //   price:'$130',
    //   image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731894264/10Ants/kaezutxesgcszt82nidm.jpg',
    //   bedrooms:'4',
    //   bathrooms:'6',
    //   country: 'Lagos',
    //   description: '',
    //   images: [],
    //   utilities: []
    // },
    {
      id: '6',
      name:'Signature Residences',
      location:'Lekki Phase one Lagos',
      price:'$150',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893441/10Ants/jc01dizxjiwjthnfxo8g.jpg',
      bedrooms:'2',
      bathrooms:'3',
      country: 'Lagos',
      description: "Enjoy a stylish experience at this centrally-located newly built apartment. This modern 2 bed,  2 bathroom residence is perfect for both leisurely vacations and productive business trips. The layout includes a well-equipped kitchen, a spacious dining area, and a comfortable living room that opens up to a balcony.",
      images: [],
      utilities: []
    },
    {
      id: '7',
      name:'Lekki Luxury Flats',
      location:'Lekki Phase one Lagos',
      price:'$150',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893403/10Ants/lwpw6ksfcpek1xvyyhaf.jpg',
      bedrooms:'3',
      bathrooms:'2',
      country: 'Lagos',
      description: "Located in a quiet family friendly neighborhood, this entire 2 bedroom 2 bath unit is spacious, cozy and well equipped with everything you'd need for a relaxing stay. Centrally located and close to all major attractions, shopping and beaches!",
      images: [],
      utilities: ['security','parking']
    },
    // {
    //   id: '8',
    //   name:'The Lofts Apartments',
    //   location:'Lekki Lagos',
    //   price:'$150',
    //   image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893504/10Ants/k5yt1jnwwotxcxfn6kvf.jpg',
    //   bedrooms:'1',
    //   bathrooms:'2',
    //   country: 'Lagos',
    //   description: '',
    //   images: [],
    //   utilities: []
    // },
    {
      id: '9',
      name:'',
      location:'London E14',
      price:'£1200',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893746/10Ants/pioh8ydimcidguai36qn.png',
      bedrooms:'5',
      bathrooms:'3',
      country: 'London',
      rate: 'month',
      description: '',
      images: [],
      utilities: []
    },
    {
      id: '10',
      name:'',
      location:'East London',
      price:'£1200',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893693/10Ants/hlwsygrunygqqly0xesv.png',
      bedrooms:'5',
      bathrooms:'3',
      country: 'London',
      rate: 'month',
      description: '',
      images: [],
      utilities: []
    },
  ];

export const galleryImages = [
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896175/PHOTO-2024-10-03-21-25-11_an6c85.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896306/PHOTO-2024-10-03-21-08-50_5_u7esq9.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896179/PHOTO-2024-10-03-21-25-16_xnrrm2.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896180/PHOTO-2024-10-03-21-25-18_goshpz.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278659/DSC09098_wzq43d.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278658/DSC09091_ursqdu.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278657/DSC09026_s2excs.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278657/DSC08987_oakaa7.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278658/DSC09065_u7fs4p.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278658/DSC09058_rp5jgm.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731893746/10Ants/pioh8ydimcidguai36qn.png",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731893862/10Ants/zllfdz2s5y2slcxnv7kf.png",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731893199/10Ants/xishomf0stkhz5hkhagb.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731894054/10Ants/xsw065paxsu2apf0rgnm.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731894149/10Ants/ktl6ojrz9jfagbeqendy.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896182/PHOTO-2024-10-03-21-25-21_azd1ta.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896182/PHOTO-2024-10-03-21-25-20_2_uufz2h.jpg",
];