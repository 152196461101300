import React, { useEffect, useState } from 'react';
import { listingsData } from '../../../data';

const NewCarousel = () => {
    const [leftSide, setLeftSide] = useState<any>([{image:''},{image:''},{image:''}]);
    const [rightSide, setRightSide] = useState<any>([{image:''},{image:''},{image:''}]);
    const [Active, setActive] = useState<any>({...listingsData[3],activeIndex: 3});

    useEffect(() => {
        const left = listingsData.filter((item,index) => {
            return index < 3
        })
        const right = listingsData.filter((item,index) => {
            return index > (listingsData.length - 4)
        })
        setLeftSide(left);
        setRightSide(right);
    }, [])
    
    const handleChange = (key:string) => {
        let index;
        if(key == 'next'){
            index = Active.activeIndex + 1;
        
            if(Active.activeIndex == 0){
                const left = listingsData.filter((item,index) => {
                    return index > (listingsData.length - 4)
                })
                const right = listingsData.filter((item,index) => {
                    return index > 0
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (Active.activeIndex == 1){
                const left = [listingsData[0],listingsData[1],listingsData[listingsData.length -1]]
                const right = listingsData.filter((item,index) => {
                    return index > 3
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (Active.activeIndex == 2){
                const left = [listingsData[0],listingsData[1],listingsData[2]]
                const right = listingsData.filter((item,index) => {
                    return index > 4
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (index == (listingsData.length - 1)){
                const left = listingsData.filter((item,index) => {
                    return index > (listingsData.length - 4)
                })
                const right = listingsData.filter((item,index) => {
                    return index > 0
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (index == (listingsData.length - 2)){
                const left = [listingsData[index - 3],listingsData[index - 2],listingsData[index - 1]]
                const right = [listingsData[0],listingsData[1],listingsData[2]];
                setLeftSide(left);
                setRightSide(right);
            } else if (index == (listingsData.length - 3)){
                const left = [listingsData[index - 3],listingsData[index - 2],listingsData[index - 1]]
                const right = [listingsData[listingsData.length - 1],listingsData[0],listingsData[1]];
                setLeftSide(left);
                setRightSide(right);
            } else {
                const left = [listingsData[index - 3],listingsData[index - 2],listingsData[index - 1]];
                const right = [listingsData[index + 1],listingsData[index + 2],listingsData[index + 3]];
                setLeftSide(left);
                setRightSide(right);
            }
    
            if(index == (listingsData.length -1)){
                setActive({...listingsData[0],activeIndex: 0})
            } else {
                setActive((prevState:any) => {return {...listingsData[prevState.activeIndex + 1], activeIndex: prevState.activeIndex + 1}})    
            }
        }else{
            index = Active.activeIndex - 1;
        
            if(index == 0){
                const left = [listingsData[index - 3],listingsData[index - 2],listingsData[index - 1]]
                const right = listingsData.filter((item,index) => {
                    return index > 0
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (index == 1){
                const left = [listingsData[listingsData.length -2],listingsData[listingsData.length - 1],listingsData[0]]
                const right = listingsData.filter((item,index) => {
                    return index > 1
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (index == 2){
                const left = [listingsData[listingsData.length - 1],listingsData[0],listingsData[1]]
                const right = listingsData.filter((item,index) => {
                    return index > 2
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (index == (listingsData.length - 1)){
                const left = listingsData.filter((item,index) => {
                    return index >= (listingsData.length - 4)
                })
                const right = listingsData.filter((item,index) => {
                    return index >= 0
                })
                setLeftSide(left);
                setRightSide(right);
            } else if (index == (listingsData.length - 2)){
                const left = [listingsData[index - 3],listingsData[index - 4],listingsData[index - 5]]
                const right = [listingsData[listingsData.length - 1],listingsData[0],listingsData[1]];
                setLeftSide(left);
                setRightSide(right);
            } else if (index == (listingsData.length - 3)){
                const left = [listingsData[index - 4],listingsData[index - 5],listingsData[index - 6]]
                const right = [listingsData[listingsData.length - 2],listingsData[listingsData.length - 1],listingsData[0]];
                setLeftSide(left);
                setRightSide(right);
            } else {
                const left = [listingsData[index - 3],listingsData[index - 2],listingsData[index - 1]];
                const right = [listingsData[index + 1],listingsData[index + 2],listingsData[index + 3]];
                setLeftSide(left);
                setRightSide(right);
            }
    
            if(index > 0){
                setActive((prevState:any) => {return {...listingsData[prevState.activeIndex - 1], activeIndex: prevState.activeIndex - 1}}) 
            } else {
                setActive({...listingsData[listingsData.length - 1],activeIndex: listingsData.length - 1})  
            }
        }
    }
    return ( 
        <div className='w-full'>
            <div className='flex w-full justify-center gap-10 mb-6 smallTablet:gap-4'>
                <div className='cursor-pointer h-[30px] my-auto' onClick={()=>handleChange('prev')}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="15" fill="#0361CC"/>
                        <path d="M11.227 14.6017L16.852 8.97672C16.9043 8.92446 16.9663 8.883 17.0346 8.85472C17.1029 8.82643 17.1761 8.81187 17.25 8.81187C17.3239 8.81187 17.3971 8.82643 17.4654 8.85472C17.5337 8.883 17.5957 8.92446 17.648 8.97672C17.7002 9.02898 17.7417 9.09102 17.77 9.15931C17.7983 9.22759 17.8128 9.30078 17.8128 9.37469C17.8128 9.4486 17.7983 9.52178 17.77 9.59007C17.7417 9.65835 17.7002 9.72039 17.648 9.77266L12.4202 14.9997L17.648 20.2267C17.7535 20.3323 17.8128 20.4754 17.8128 20.6247C17.8128 20.774 17.7535 20.9171 17.648 21.0227C17.5424 21.1282 17.3993 21.1875 17.25 21.1875C17.1007 21.1875 16.9576 21.1282 16.852 21.0227L11.227 15.3977C11.1747 15.3454 11.1333 15.2834 11.1049 15.2151C11.0766 15.1468 11.0621 15.0736 11.0621 14.9997C11.0621 14.9258 11.0766 14.8526 11.1049 14.7843C11.1333 14.716 11.1747 14.654 11.227 14.6017Z" fill="white"/>
                    </svg>
                </div>
                <section className='flex w-9/12 gap-3 justify-center'>
                    <div className='relative cursor-pointer w-[87px] h-[283px] my-auto smallTablet:hidden'>
                        <img src={leftSide[0].image} className='rounded-lg w-full h-full object-cover'/>
                    </div>
                    <div className='relative cursor-pointer w-[87px] h-[347px] my-auto smallTablet:hidden'>
                        <img src={leftSide[1].image} className='rounded-lg w-full h-full object-cover'/>
                    </div>
                    <div className='relative cursor-pointer w-[87px] h-[394px] my-auto smallTablet:hidden'>
                        <img src={leftSide[2].image} className='rounded-lg w-full h-full object-cover'/>
                    </div>
                    <div className='relative cursor-pointer w-[430px] h-[434px] my-auto'>
                        <img src={Active.image} className='rounded-lg w-full h-full object-cover slide-in-right'/>
                        <div className='absolute top-2 right-3 cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.25 4.5V9C20.25 9.19891 20.171 9.38968 20.0303 9.53033C19.8897 9.67098 19.6989 9.75 19.5 9.75C19.3011 9.75 19.1103 9.67098 18.9697 9.53033C18.829 9.38968 18.75 9.19891 18.75 9V6.31031L14.0306 11.0306C13.8899 11.1714 13.699 11.2504 13.5 11.2504C13.301 11.2504 13.1101 11.1714 12.9694 11.0306C12.8286 10.8899 12.7496 10.699 12.7496 10.5C12.7496 10.301 12.8286 10.1101 12.9694 9.96937L17.6897 5.25H15C14.8011 5.25 14.6103 5.17098 14.4697 5.03033C14.329 4.88968 14.25 4.69891 14.25 4.5C14.25 4.30109 14.329 4.11032 14.4697 3.96967C14.6103 3.82902 14.8011 3.75 15 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5ZM9.96937 12.9694L5.25 17.6897V15C5.25 14.8011 5.17098 14.6103 5.03033 14.4697C4.88968 14.329 4.69891 14.25 4.5 14.25C4.30109 14.25 4.11032 14.329 3.96967 14.4697C3.82902 14.6103 3.75 14.8011 3.75 15V19.5C3.75 19.6989 3.82902 19.8897 3.96967 20.0303C4.11032 20.171 4.30109 20.25 4.5 20.25H9C9.19891 20.25 9.38968 20.171 9.53033 20.0303C9.67098 19.8897 9.75 19.6989 9.75 19.5C9.75 19.3011 9.67098 19.1103 9.53033 18.9697C9.38968 18.829 9.19891 18.75 9 18.75H6.31031L11.0306 14.0306C11.1714 13.8899 11.2504 13.699 11.2504 13.5C11.2504 13.301 11.1714 13.1101 11.0306 12.9694C10.8899 12.8286 10.699 12.7496 10.5 12.7496C10.301 12.7496 10.1101 12.8286 9.96937 12.9694Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='absolute bottom-5 w-full text-left left-6 smallTablet:left-3'>
                            <h1 className='text-white font-semibold text-xl mb-2'>{Active.name}{Active.name && ','} {Active.location}.</h1>
                            <p className='text-white font-light text-sm leading-6'>High-end residential estate in {Active.country}.</p>
                        </div>
                    </div>
                    <div className='relative cursor-pointer w-[87px] h-[394px] my-auto smallTablet:hidden'>
                        <img src={rightSide[0].image} className='rounded-lg w-full h-full object-cover'/>
                    </div>
                    <div className='relative cursor-pointer w-[87px] h-[347px] my-auto smallTablet:hidden'>
                        <img src={rightSide[1].image} className='rounded-lg w-full h-full object-cover'/>
                    </div>
                    <div className='relative cursor-pointer w-[87px] h-[283px] my-auto smallTablet:hidden'>
                        <img src={rightSide[2].image} className='rounded-lg w-full h-full object-cover'/>
                    </div>
                </section>
                <div className='cursor-pointer h-[30px] my-auto' onClick={()=>handleChange('next')}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="15" fill="#0361CC"/>
                        <path d="M18.7729 15.3983L13.1479 21.0233C13.0957 21.0755 13.0336 21.117 12.9653 21.1453C12.897 21.1736 12.8239 21.1881 12.7499 21.1881C12.676 21.1881 12.6029 21.1736 12.5346 21.1453C12.4663 21.117 12.4042 21.0755 12.352 21.0233C12.2997 20.971 12.2583 20.909 12.23 20.8407C12.2017 20.7724 12.1871 20.6992 12.1871 20.6253C12.1871 20.5514 12.2017 20.4782 12.23 20.4099C12.2583 20.3417 12.2997 20.2796 12.352 20.2273L17.5797 15.0003L12.352 9.77328C12.2464 9.66773 12.1871 9.52458 12.1871 9.37531C12.1871 9.22605 12.2464 9.08289 12.352 8.97734C12.4575 8.8718 12.6007 8.8125 12.7499 8.8125C12.8992 8.8125 13.0424 8.8718 13.1479 8.97734L18.7729 14.6023C18.8252 14.6546 18.8667 14.7166 18.895 14.7849C18.9233 14.8532 18.9379 14.9264 18.9379 15.0003C18.9379 15.0742 18.9233 15.1474 18.895 15.2157C18.8667 15.284 18.8252 15.346 18.7729 15.3983Z" fill="white"/>
                    </svg>
                </div>
            </div>
            <ul className='mx-auto w-[155px] flex gap-2 list-none cursor-not-allowed'>
                <li className={`h-1 w-[32px] rounded-sm ${Active.activeIndex < 5 ? 'bg-[#0361CC]' : 'bg-[#0361CC1A]'}`}></li>
                <li className={`h-1 w-[32px] rounded-sm ${Active.activeIndex == 5 ? 'bg-[#0361CC]' : 'bg-[#0361CC1A]'}`}></li>
                <li className={`h-1 w-[32px] rounded-sm ${Active.activeIndex > 5 && Active.activeIndex < (listingsData.length -1) ? 'bg-[#0361CC]' : 'bg-[#0361CC1A]'}`}></li>
                <li className={`h-1 w-[32px] rounded-sm ${Active.activeIndex == (listingsData.length - 1) ? 'bg-[#0361CC]' : 'bg-[#0361CC1A]'}`}></li>
            </ul>
        </div>
     );
}
 
export default NewCarousel;